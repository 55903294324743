import { Props } from './AppBadge'

import global from 'scss/global.module.scss'
import styles from './AppBadge.module.scss'

const helper = {
  init: (props: Props) => {
    const classes = [styles.badge]
    if (props.active) {
      classes.push(styles.active)
    }
    return classes
  },

  size: (props: Props) => {
    const defaultValue = null
    return (props.large ? styles.large : null)
      ?? (props.small ? styles.small : null)
      ?? (props.xl ? styles.xl : null)
      ?? (props.xs ? styles.xs : null)
      ?? defaultValue
  },

  widthClass: (props: Props) => {
    const defaultValue = global.wAuto
    return (props.full ? global.wFull : null)
      ?? (props.fullWidth ? global.wFull : null)
      ?? (props.halfWidth ? global.wHalf : null)
      ?? defaultValue
  },

  heightClass: (props: Props) => {
    const defaultValue = global.hAuto
    return (props.full ? global.hFull : null)
      ?? (props.fullHeight ? global.hFull : null)
      ?? (props.halfHeight ? global.hHalf : null)
      ?? defaultValue
  },
}

export default helper