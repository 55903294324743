import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'
import $customer from 'store/customer'
import $day from 'store/day'

import App from 'components/App'
import AppointmentQuickSelection from 'components/Appointment/AppointmentQuickSelection'
import AppointmentCalendar from 'components/Appointment/AppointmentCalendar'
import AppointmentTime from 'components/Appointment/AppointmentTime'

import styles from './AppointmentWhen.module.scss'

export type Props = {
  onScrollDown?: (value: boolean) => void
}

const AppointmentWhen: React.FC<Props> = ({ onScrollDown }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const month = useAppSelector(({ $day }) => $day.month)
  const year = useAppSelector(({ $day }) => $day.year)
  const phone = useAppSelector($customer.get.phone)
  const selectedServiceId = useAppSelector(({ $service }) => $service.selectedId)
  const selectedResourceId = useAppSelector(({ $resource }) => $resource.selectedId)
  const editId = useAppSelector(({ $order }) => $order.editId)
  
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchDays()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year])

  const fetchDays = async () => {
    setLoading(true)

    const result = await $day.api.all({
      number: phone,
      month,
      year,
      service: selectedServiceId,
      resource: selectedResourceId,
      order_id: editId,
    })

    if (result && result.status === 'success') {
      dispatch($day.set.all(result.data))
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }

    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const handleScrollDown = (value: boolean) => {
    if (onScrollDown) {
      onScrollDown(value)
    }
  }

  return loading ? (
    <App.Flex center height={343}>
      <App.Loader xl />
    </App.Flex>
  ) : (
    <App.Flex column gap={6} className={styles.content}>
      <AppointmentQuickSelection onScroll={handleScrollDown} />
      <AppointmentCalendar onScroll={handleScrollDown} />
      <AppointmentTime onScroll={handleScrollDown} />
    </App.Flex>
  )
}

export default AppointmentWhen