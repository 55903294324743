import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'
import $business from 'store/business'
import $customer from 'store/customer'
import $category from 'store/category'
import $service from 'store/service'
import $resource from 'store/resource'

import App from 'components/App'
import AppointmentCategories from 'components/Appointment/AppointmentCategories'
import AppointmentServices from 'components/Appointment/AppointmentServices'
import AppointmentResources from 'components/Appointment/AppointmentResources'

import styles from './AppointmentWhat.module.scss'

export type Props = {
  onScrollDown?: (value: boolean) => void
}

const AppointmentWhat: React.FC<Props> = ({ onScrollDown }) => {
  const { t } = useTranslation()
  
  const dispatch = useAppDispatch()
  const settings = useAppSelector($business.get.settings)
  const phone = useAppSelector($customer.get.phone)
  const resources = useAppSelector($resource.get.filtered)
  const editId = useAppSelector(({ $order }) => $order.editId)
  const selectedCategoryIds = useAppSelector(({ $category }) => $category.selectedIds)
  const selectedServiceId = useAppSelector(({ $service }) => $service.selectedId)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    setLoading(true)

    const calls = [fetchServices()]

    if (settings.display_category) {
      calls.push(fetchCategories())
    }

    if (settings.display_resource) {
      calls.push(fetchResources())
    }

    await Promise.all(calls)

    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const fetchCategories = async () => {
    const result = await $category.api.all({ number: phone })
    if (result && result.status === 'success') {
      dispatch($category.set.all(result.data))
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }
  }

  const fetchServices = async () => {
    const result = await $service.api.all({ number: phone })
    if (result && result.status === 'success') {
      dispatch($service.set.all(result.data))

      if (editId && selectedServiceId) {
        if (!selectedCategoryIds.length && settings.display_category) {
          const categoryId = result.data.find((item: any) => item.id === selectedServiceId)?.categories
          if (categoryId) {
            dispatch($category.set.selectedIds([Number(categoryId)]))
          }
        }
      }
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }
  }

  const fetchResources = async () => {
    const result = await $resource.api.all()
    if (result && result.status === 'success') {
      dispatch($resource.set.all(result.data))
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }
  }

  const handleScrollDown = (value: boolean) => {
    if (onScrollDown && settings.display_resource && resources.length) {
      onScrollDown(value)
    }
  }

  return loading ? (
    <App.Flex center height={343}>
      <App.Loader xl />
    </App.Flex>
  ) : (
    <App.Flex column gap={6} className={styles.content}>
      <AppointmentCategories />
      <AppointmentServices onScroll={handleScrollDown} />
      <AppointmentResources />
    </App.Flex>
  )
}

export default AppointmentWhat