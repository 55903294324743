import React from 'react'

import cn from 'classnames'

import AppFlex from 'components/App/AppFlex'
import AppText from 'components/App/AppText'
import AppIcon from 'components/App/AppIcon'

import helper from './helper'

export type Props = {
  children?: React.ReactNode
  icon?: string
  value?: string | number
  active: boolean
  size?: number | Array<number>
  large?: boolean
  small?: boolean
  xl?: boolean
  xs?: boolean
  full?: boolean
  width?: number | string | Array<number | string>
  fullWidth?: boolean
  halfWidth?: boolean
  height?: number | string | Array<number | string>
  fullHeight?: boolean
  halfHeight?: boolean
  style?: React.CSSProperties
  className?: string
  onClick?: (value: string | number) => void
}

const AppBadge: React.FC<Props> = (props) => {
  const classes = [
    ...helper.init(props),
    helper.size(props),
    helper.widthClass(props),
    helper.heightClass(props),
    props.className,
  ]

  const styles = {
    ...(props.style ?? {}),
  }

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.value ?? '')
    }
  }

  return (
    <AppFlex row center gap={1} className={cn(classes)} style={styles} onClick={handleClick}>
      {props.icon ? (
        <AppIcon icon={props.icon} />
      ) : null}

      <AppText nowrap small medium highlight={props.active}>{props.children}</AppText>
    </AppFlex>
  )
}

export default AppBadge