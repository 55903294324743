import { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import useInterval from 'hooks/useInterval'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'
import $customer from 'store/customer'

import App from 'components/App'

import styles from './DialogCodeConfirm.module.scss'

export type Props = {
  open: boolean
  onSuccess: () => void
  onClose: () => void
}

const DialogCodeConfirm: React.FC<Props> = ({ open, onSuccess, onClose }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const phone = useAppSelector($customer.get.phone)

  const [code, setCode] = useState(['', '', '', ''])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [time, setTime] = useState(0)
  const [duration, setDuration] = useState({
    minutes: '00',
    seconds: '00',
    minutesNumber: 0,
    secondsNumber: 0,
    isEnd: false,
  })

  const inputRef0 = useRef<HTMLInputElement>(null)
  const inputRef1 = useRef<HTMLInputElement>(null)
  const inputRef2 = useRef<HTMLInputElement>(null)
  const inputRef3 = useRef<HTMLInputElement>(null)

  const refs = { inputRef0, inputRef1, inputRef2, inputRef3 }
  const delayBetweenSms = 60 // is seconds

  useEffect(() => {
    if (open && phone !== '') {
      sendCode()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, phone])

  useEffect(() => {
    if (inputRef0?.current) {
      inputRef0.current?.focus()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef0?.current])

  useEffect(() => {
    if (time >= 0) {
      setDuration(getDuration())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  const getDuration = () => {
    const duration = moment.duration(time, 'seconds')
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    return {
      minutes: (minutes > 9 ? minutes : `0${minutes}`).toString(),
      seconds: (seconds > 9 ? seconds : `0${seconds}`).toString(),
      minutesNumber: minutes,
      secondsNumber: seconds,
      isEnd: time <= 0,
    }
  }

  const tick = () => {
    setTime(time - 1)
  }

  useInterval(tick, duration.isEnd ? null : 1000)

  const sendCode = async () => {
    const sentAt = localStorage.getItem('sentAt')
    if (sentAt) {
      const timestamp = JSON.parse(sentAt)
      setTime(delayBetweenSms - moment().diff(moment(timestamp), 'seconds'))
      const diff = moment().diff(moment(timestamp), 'seconds')
      if (diff < delayBetweenSms) {
        return 
      }
    }

    const result = await $customer.api.sendCode({ number: phone })
    if (result && result.status === 'success') {
      const currentTimestamp = moment().valueOf()
      localStorage.setItem('sentAt', JSON.stringify(currentTimestamp))
      setTime(delayBetweenSms - moment().diff(moment(currentTimestamp), 'seconds'))
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }
  }

  const handleCode = (index: number) => (value: string) => {
    setError(t(''))

    const newCode = [...code]

    if (value === '' && index > 0) {
      newCode[index] = value
      const prevInputRef = `inputRef${index - 1}` as keyof typeof refs
      refs[prevInputRef]?.current?.focus()
    } else if (value !== '' && index < code.length - 1) {
      newCode[index] = value.slice(0, 1)
      const nextInputRef = `inputRef${index + 1}` as keyof typeof refs
      refs[nextInputRef]?.current?.focus()
    } else {
      newCode[index] = value.slice(0, 1)
    }

    setCode(newCode)
  }

  const handlePaste = async (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()

    const pastedData = event.clipboardData.getData('text')
    if (/^\d{4}$/.test(pastedData)) {
      setCode([pastedData[0], pastedData[1], pastedData[2], pastedData[3]])
      inputRef3?.current?.focus()
    }
  }

  const isNotFullCode = () => {
    return code.some((value) => value === '')
  }

  const handleCheckCode = async () => {
    if (!isNotFullCode()) {
      setLoading(true)

      const result = await $customer.api.checkCode({ number: phone, code: code.join('') })
      if (result && result.status === 'success') {
        if (result.check) {
          if (onSuccess) {
            onSuccess()
          }

          dispatch($app.set.phone(phone))
          dispatch($customer.set.confirmed(true))
          
          onClose()
        } else {
          setError(t('Wrong confirmation code'))
        }
      } else {
        dispatch($app.set.error({ message: t(result?.message) }))
      }

      setLoading(false)
    }
  }
  
  return (
    <App.Dialog open={open} onClose={onClose}>
      <App.Flex column gap={6} className={styles.container}>
        <App.Flex column gap={3} aCenter>
          <App.Text center large bold>{t('Confirmation code')}</App.Text>
          <App.Text center muted>{t('Finish your registration by entering code from the SMS  sent to your phone number')}</App.Text>
        </App.Flex>

        <App.Flex column gap={1}>
          <App.Flex dir="ltr" row gap={4}>
            <App.Input ref={inputRef0} center flex={1} type="number" limit={1} value={code[0]} danger={error !== ''} onChange={handleCode(0)} onPaste={handlePaste} onEnter={handleCheckCode} />
            <App.Input ref={inputRef1} center flex={1} type="number" limit={1} value={code[1]} danger={error !== ''} onChange={handleCode(1)} onPaste={handlePaste} onEnter={handleCheckCode} />
            <App.Input ref={inputRef2} center flex={1} type="number" limit={1} value={code[2]} danger={error !== ''} onChange={handleCode(2)} onPaste={handlePaste} onEnter={handleCheckCode} />
            <App.Input ref={inputRef3} center flex={1} type="number" limit={1} value={code[3]} danger={error !== ''} onChange={handleCode(3)} onPaste={handlePaste} onEnter={handleCheckCode} />
          </App.Flex>

          {error !== '' ? (
            <App.Flex center>
              <App.Text center small danger>{error}</App.Text>
            </App.Flex>
          ) : null}
        </App.Flex>

        <App.Flex center>
          {duration.isEnd ? (
            <App.Text small highlight onClick={sendCode} style={{ cursor: 'pointer' }}>{t('Send code again')}</App.Text>
          ) : (
            <App.Text small muted>{t('You can resend the code in')} {duration.minutes}:{duration.seconds}</App.Text>
          )}
        </App.Flex>

        <App.Button loading={loading} disabled={isNotFullCode()} onClick={handleCheckCode}>{t('Confirm')}</App.Button>
      </App.Flex>
    </App.Dialog>
  )
}

const isEqual = (prev: Props, next: Props) => {
  return prev.open === next.open
  && prev.onClose === next.onClose
}

export default memo(DialogCodeConfirm, isEqual)