import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'
import $customer from 'store/customer'
import $order from 'store/order'

import App from 'components/App'
import HomeReservationsItem from 'components/Home/HomeReservations/HomeReservationsItem'

const HomeReservations: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const phone = useAppSelector($customer.get.phone)
  const customer = useAppSelector(({ $customer }) => $customer.data)
  const fetch = useAppSelector(({ $order }) => $order.fetch)
  const orders = useAppSelector(({ $order }) => $order.all)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (phone && fetch && customer.confirmed) {
      fetchOrders()
      dispatch($order.set.fetch(false))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, fetch, customer.confirmed])

  const fetchOrders = async () => {
    setLoading(true)

    const result = await $order.api.all({ number: phone })
    if (result && result.status === 'success') {
      dispatch($order.set.all(result.data))
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }

    setLoading(false)
  }

  return loading ? (
    <App.Flex center>
      <App.Loader xl />
    </App.Flex>
  ) :(
    orders.length ? (
      <App.Flex column gap={4}>
        <App.Text bold>{t('Your reservations')}:</App.Text>
        
        {orders.map((item, index) => (
          <HomeReservationsItem key={index} item={item} />
        ))}
      </App.Flex>
    ) : null
  )
}

export default HomeReservations