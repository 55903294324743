import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import cn from 'classnames'

import styles from './AppDialog.module.scss'

export type Props = {
  children?: React.ReactNode
  open: boolean
  doNotClose?: boolean
  actions?: React.ReactNode
  scrollDependensies?: any
  style?: React.CSSProperties
  scrollDown?: boolean
  whiteBack?: boolean
  className?: string
  onClose: () => void
}

const AppDialog: React.FC<Props> = (props) => {
  const [opened, setOpened] = useState(false)
  const [scrollBottom, setScrollBottom] = useState(false)

  const layout = useRef(null)
  const content = useRef(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (props.scrollDown && scrollRef.current) {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight,
          behavior: 'smooth'
        })
      }
    }
  }, [props.scrollDown])

  useEffect(() => {
    if (scrollRef.current) {
      processShadow(scrollRef.current)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scrollDependensies])

  useEffect(() => {
    if (props.open) {
      if (!opened) {
        document.body.classList.add('modal-open')
        Promise.all([
          gsap.to(layout.current, {opacity: 1, duration: 0.2}),
          gsap.fromTo(content.current, {y: 200}, {y: 0, duration: 0.2}),
        ]).then(() => {
          setOpened(true)
        })
      }
    } else {
      if (opened) {
        Promise.all([
          gsap.to(layout.current, {opacity: 0, duration: 0.2}),
          gsap.fromTo(content.current, {y: 0}, {y: 200, duration: 0.2}),
        ]).then(() => {
          document.body.classList.remove('modal-open')
          setOpened(false)
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    processShadow(event.currentTarget)
  }

  const processShadow = (div: HTMLDivElement) => {
    const { scrollTop, clientHeight, scrollHeight } = div

    if (scrollTop + clientHeight + 2 >= scrollHeight) {
      setScrollBottom(true)
    } else {
      if (scrollBottom === true) {
        setScrollBottom(false)
      }
    }
  }

  const handleClose = () => {
    if (props.onClose && !props.doNotClose) {
      props.onClose()
    }
  }
  
  return props.open || opened ? (
    <div ref={layout} className={styles.layout}>
      <div ref={content} className={styles.content} onClick={handleClose}>
        <div ref={scrollRef} className={styles.inner} onScroll={handleScroll} onClick={e => e.stopPropagation()}>
          <div>
            <span onClick={handleClose} />
            <div className={styles.dialog}>
              {props.children}
            </div>
          </div>
        </div>
      </div>

      {props.actions ? (
        <div className={cn(styles.actions, {[styles.bottom]: scrollBottom})}>
          {props.actions}
        </div>
      ) : null}

      {props.whiteBack ? (
        <div className={styles.backWhite} />
      ) : null}
    </div>
  ) : null
}

export default AppDialog