import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import cn from 'classnames'

import { useAppDispatch, useAppSelector } from 'store'
import $business from 'store/business'
import $day from 'store/day'

import App from 'components/App'

import styles from './AppointmentQuickSelection.module.scss'

export type Props = {
  onScroll?: (value: boolean) => void
}

const AppointmentQuickSelection: React.FC<Props> = ({ onScroll }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const settings = useAppSelector($business.get.settings)
  const days = useAppSelector(({ $day }) => $day.all)
  const formatTime = useAppSelector($day.get.formatTime)

  const [quickTime, setQuickTime] = useState<string>('')
  const [quickTimeOptions, setQuickTimeOptions] = useState<Array<{ value: string | number, label: string }>>([])
  const [expand, setExpand] = useState(false)

  useEffect(() => {
    getOptions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days])

  useEffect(() => {
    if (formatTime) {
      const result = quickTimeOptions.find((item: { value: string | number }) => item.value === formatTime)
      if (result) {
        setQuickTime(formatTime)
        return
      }
    }

    setQuickTime('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatTime])

  const getOptions = () => {
    const dateTimeArray = Object.entries(days).flatMap(([date, times]) =>
      Array.isArray(times) ? times.map((time: string) => ({ date, time })) : []
    )

    const sortedDateTimeArray = dateTimeArray.sort((a, b) => 
      a.date.localeCompare(b.date) || a.time.localeCompare(b.time)
    )
    
    const options = sortedDateTimeArray.slice(0, 5).map(({ date, time }) => {
      const dateTimeMoment = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm')
      const label = dateTimeMoment.format('HH:mm - DD/MM/YYYY')

      return { value: `${date} ${time}`, label }
    })

    setQuickTimeOptions(options)
  }

  const handleQuickTimeChange = (value: string | number) => {
    const stringValue = String(value) === quickTime ? '' : String(value)
    setQuickTime(stringValue)

    if (stringValue !== '') {
      const [date, time] = stringValue.split(' ')
      dispatch($day.set.selectedDay(Number(moment(date).format('D'))))
      dispatch($day.set.selectedTime(time))
      if (onScroll) {
        onScroll(true)
      }

      handleExpand()
    } else {
      dispatch($day.set.selectedDay(null))
      dispatch($day.set.selectedTime(null))
    }
  }

  const handleExpand = () => {
    setExpand(!expand)
  }
  
  return settings.display_earliest && quickTimeOptions.length ? (
    <App.Flex column gap={6}>
      <App.Flex column gap={4}>
        <App.Text bold>{t('Quick selection')}:</App.Text>

        <App.Card active>
          <App.Flex column fullWidth>
            <App.Flex row aCenter jBetween className={styles.title} onClick={handleExpand}>
              <App.Flex row gap={1} aCenter>
                <App.Icon icon="clock" variant={quickTime ? 'primary' : 'muted'}/>
                {quickTime ? (
                  <App.Text highlight>{quickTimeOptions.find((item) => item.value === quickTime)?.label}</App.Text>
                ) : (
                  <App.Text muted>{t('Select available date from a list')}</App.Text>
                )}
              </App.Flex>
              <App.Icon icon="chevron-down" style={{ transform: `rotate(${expand ? 180 : 0}deg)` }} />
            </App.Flex>

            <App.Flex fullWidth column>
              <App.Flex column className={cn(styles.details, {[styles.hide]: !expand})}>
                <App.Flex column gap={4} paddingTop={4}>
                  {quickTimeOptions.map((item, index) => (
                    <App.Badge key={index} fullWidth active={item.value === quickTime} value={item.value} onClick={handleQuickTimeChange}>{item.label}</App.Badge>
                  ))}
                </App.Flex>
              </App.Flex>
            </App.Flex>
          </App.Flex>
        </App.Card>

        {/* <App.Select
          value={quickTime}
          options={quickTimeOptions}
          placeholder="Select available date from a list"
          onChange={handleQuickTimeChange}
        /> */}
      </App.Flex>
    </App.Flex>
  ) : null
}

export default AppointmentQuickSelection