import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import cn from 'classnames'

import { useAppSelector } from 'store'
import $business from 'store/business'
import $customer from 'store/customer'

import App from 'components/App'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './HomeHero.module.scss'

const HomeHero: React.FC = () => {
  const { t } = useTranslation()

  const design = useAppSelector($business.get.design)
  const customer = useAppSelector(({ $customer }) => $customer.data)
  const name = useAppSelector($customer.get.name)

  const [active, setActive] = useState(0)

  const sliderRef = useRef<Slider | null>(null)

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_: number, newIndex: number) => {
      setActive(newIndex)
    },
  }

  const handleSlide = (index: number) => () => {
    sliderRef.current?.slickGoTo(index)
  }

  return (
    <App.Flex column className={styles.container}>
      {design.slides && design.slides.length > 1 ? (
        <App.Flex column className={styles.slider}>
          <Slider ref={slider => { sliderRef.current = slider }} {...settings}>
            {design.slides.map((slide, index) => (
              <div key={index}>
                <div className={styles.slide} style={{ backgroundImage: `url(${slide})` }}>
                </div>
              </div>
            ))}
          </Slider>

          <App.Flex row center gap={2} className={styles.dots}>
            {design.slides.map((_, index) => <div key={index} className={cn(styles.dot, {[styles.active]: index === active})} onClick={handleSlide(index)}/>)}
          </App.Flex>
        </App.Flex>
      ) : (
        <App.Flex className={styles.slide} style={{ backgroundImage: `url(${design.background})` }}>
        </App.Flex>
      )}

      {customer.loggedIn && customer.firstname !== '' ? (
        <App.Flex center className={styles.welcome}>
          <App.Text center large color={design.text}>{t('Welcome back')}, <b>{name}</b> 👋</App.Text>
        </App.Flex>
      ) : null}
    </App.Flex>
  )
}

export default HomeHero