import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import Theme from 'utils/Theme'
import PWA from 'utils/PWA'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'
import $business, { BusinessType } from 'store/business'

import App from 'components/App'
import HomeContent from 'components/Home/HomeContent'
import TheLoader from 'components/TheLoader'

import styles from './HomePage.module.scss'

const HomePage: React.FC = () => {
  const params = useParams()
  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()
  const isRtl = useAppSelector(({ $app }) => $app.isRtl)
  const design = useAppSelector($business.get.design)
  const manifest = useAppSelector($business.get.manifest)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (manifest?.name) {
      PWA.manifest(manifest)
      // PWA.events()
    }
  }, [manifest])

  useEffect(() => {
    if (params?.code) {
      fetchBusiness(params.code)
    }

    dispatch($app.set.whitelist())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.code])

  useEffect(() => {
    if (design.loaded) {
      Theme.init(design.color)
    }

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [design])

  const fetchBusiness = async (code: string) => {
    const result = await $business.api.get(code)
    if (result && result.status === 'success') {
      const data = result.data as BusinessType
      dispatch($business.set.code(code))
      dispatch($business.set.data(data))
      dispatch($app.set.isRtl(data.language_code === 'hb_ISR' || data.language_code === 'ar_AR'))

      if (data?.language_code) {
        i18n.changeLanguage(data.language_code)
      }
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }
  }

  return (
    <App.Flex column center style={{ backgroundImage: `url(${design.background})` }} className={cn(styles.container, {[styles.rtl]: isRtl})}>
      <App.Flex column className={styles.card}>
        {design.loaded ? (
          <HomeContent />
        ) : (
          <App.Flex column full center>
            <App.Text xl bold highlight>404</App.Text>
            <App.Text highlight>{t('Page not found')}</App.Text>
          </App.Flex>
        )}
      </App.Flex>

      <TheLoader loading={loading} />
    </App.Flex>
  )
}

export default HomePage