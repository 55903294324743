import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store'
import $order from 'store/order'
import $day from 'store/day'

import App from 'components/App'

import styles from './AppointmentTime.module.scss'

export type Props = {
  onScroll?: (value: boolean) => void
}

const AppointmentTime: React.FC<Props> = ({ onScroll }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const selectedDay = useAppSelector(({ $day }) => $day.selectedDay)
  const selectedTime = useAppSelector(({ $day }) => $day.selectedTime)
  const times = useAppSelector($day.get.times)
  const comment = useAppSelector(({ $order }) => $order.comment)

  useEffect(() => {
    if (selectedDay && onScroll) {
      onScroll(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay])

  useEffect(() => {
    if (selectedTime) {
      if (!times.includes(selectedTime)) {
        dispatch($day.set.selectedTime(null))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, selectedTime])

  const handleTimeToggle = (time: string) => () => {
    dispatch($day.set.selectedTime(time === selectedTime ? null : time))
    if (onScroll) {
      onScroll(time !== selectedTime)
    }
  }

  const handleCommentChange = (value: string) => {
    dispatch($order.set.comment(value))
  }

  return times.length ? (
    <App.Flex column gap={6}>
      <App.Flex column gap={4}>
        <App.Text bold>{t('Select time')}:</App.Text>

        <App.Flex wrap gap={2}>
          {times.map((time: string) => (
            <App.Badge key={time} active={selectedTime === time} value={time} onClick={handleTimeToggle(time)} className={styles.time}>
              {time}
            </App.Badge>
          ))}
        </App.Flex>
      </App.Flex>

      {selectedTime ? (
        <App.Flex column gap={4}>
          <App.Text bold>{t('Comment')}:</App.Text>

          <App.Textarea
            value={comment}
            rows={4}
            placeholder={t('Enter all your requests about services or specialist')}
            onChange={handleCommentChange}
          />
        </App.Flex>
      ) : null}
    </App.Flex>
  ) : null
}

export default AppointmentTime