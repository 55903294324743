import DialogCancelOrderConfirm from './DialogCancelOrderConfirm'
import DialogCodeConfirm from './DialogCodeConfirm'
import DialogError from './DialogError'
import DialogNotAllowBooking from './DialogNotAllowBooking'

const Dialog = {
  CancelOrderConfirm: DialogCancelOrderConfirm,
  CodeConfirm: DialogCodeConfirm,
  Error: DialogError,
  NotAllowBooking: DialogNotAllowBooking,
}

export default Dialog