import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { request, businessCode } from './index'

export type ServiceType = {
  id: number
  name: string
  name_second: string
  bg_color: string
  categories: number
  duration: number
  duration_second: number
  break: number
  real_duration: number
  formated_duration: {h: number, m: number}
  item_image: string | null
  item_type: string
  price: number
  stock: string
  norders: number
}

type StateType = {
  all: Array<ServiceType>
  selectedId: number | null
}

const initialState: StateType = {
  all: [],
  selectedId: null,
}

export const serviceSlice = createSlice({
  name: '$service',

  initialState,

  reducers: {
    all: (state: StateType, action: PayloadAction<Array<ServiceType>>) => {
      state.all = action.payload.map((item: ServiceType) => {
        const duration = item.duration ?? 0
        const duration_second = item.duration_second ?? 0
        const break_number = item.break ?? 0
        const real_duration = break_number > 0 ? (duration + duration_second + break_number) : duration
        const formated_duration = {h: 0, m: real_duration}
        const item_image = item.item_image === '' || !item.item_image ? null : item.item_image
        
        if (real_duration > 60) {
          formated_duration.h = Math.floor(real_duration / 60)
          formated_duration.m = real_duration % 60
        }

        return {
          ...item,
          categories: Number(item.categories),
          duration,
          duration_second,
          break_number,
          real_duration,
          formated_duration,
          item_image,
          norders: item?.norders ?? 0,
        }
      })
    },

    selectedId: (state: StateType, action: PayloadAction<number | null>) => {
      state.selectedId = action.payload
    },

    reset: (state: StateType) => {
      state.selectedId = null
    },
  },
})

const get = {
  selected: createSelector([
    state => state.$service.all,
    state => state.$service.selectedId,
  ], (services, id) => {
    return services.find((item: ServiceType) => item.id === id) ?? null
  }),

  filtered: createSelector([
    state => state.$service.all,
    state => state.$category.selectedIds,
  ], (services, selectedCategoryIds) => {
    if (selectedCategoryIds.length) {
      return services.filter((item: ServiceType) => selectedCategoryIds.includes(item.categories))
    }
    return services
  }),

  isDisabled: createSelector([
    state => state.$business.data,
    state => state.$category.selectedIds,
  ], (business, selectedCategoryIds) => {
    const display_categories = business.online_booking_data?.display_category === '1'
    return display_categories && selectedCategoryIds.length === 0
  }),
}

const api = {
  all: (params: object) => {
    return request(`services/id/${businessCode()}`, 'POST', params)
  },
}

const service = {
  reducer: serviceSlice.reducer,
  set: serviceSlice.actions,
  get,
  api,
}

export default service