import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import i18n from 'utils/i18n'

export type ErrorType = {
  title?: string,
  message?: string,
  button?: string,
}

type StateType = {
  isRtl: boolean
  slide: number,
  reset: boolean,
  whitelist: Array<string>,
  error: ErrorType | null,
}

const initialState: StateType = {
  reset: false,
  isRtl: false,
  slide: 0,
  whitelist: [],
  error: null,
}

export const appSlice = createSlice({
  name: '$app',

  initialState,

  reducers: {
    isRtl: (state: StateType, action: PayloadAction<boolean>) => {
      state.isRtl = action.payload
      document.documentElement.dir = action.payload ? 'rtl' : 'ltr'
    },

    slide: (state: StateType, action: PayloadAction<number>) => {
      state.slide = action.payload
    },

    reset: (state: StateType, action: PayloadAction<boolean>) => {
      state.reset = action.payload
    },

    whitelist: (state: StateType) => {
      const whitelist = localStorage.getItem('customerWhitelist')
      if (whitelist) {
        state.whitelist = JSON.parse(whitelist)
      }
    },

    phone: (state: StateType, action: PayloadAction<string>) => {
      const exists = state.whitelist.find((item) => item === action.payload)
      if (!exists) {
        const whitelist = [...state.whitelist]
        whitelist.push(action.payload)
        state.whitelist = whitelist

        localStorage.setItem('customerWhitelist', JSON.stringify(whitelist))
      }
    },

    error: (state: StateType, action: PayloadAction<ErrorType | null>) => {
      state.error = action.payload
    },
  },
})

const get = {
  error: createSelector([
    state => state.$app.error,
  ], (error) => {
    if (error) {
      return {
        title: error.title || i18n.t('Oops, something went wrong...'),
        message: error.message || '',
        button: error.button || i18n.t('Got it'),
      }
    }

    return null
  }),
}

const app = {
  reducer: appSlice.reducer,
  set: appSlice.actions,
  get,
}

export default app